module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://go.frontier.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-N7M9RSQ","brandTokens":["FTR","FTRB"],"mapiBrandToken":"FTW","siteName":"go.frontier","alternateName":"go.frontier.com","siteURL":"https://go.frontier.com","defaultTitleTemplate":"","defaultPhone":"8556424943","phoneSymbol":"-","defaultPromoCode":"57724","smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10045796","vwoProjectID":"894940","datasetManagerAPIKey":"358ea773800344e78f353d315727621b","chatEnabled":true,"cobra":{"sitePath":"go.frontier.com","buttonLink":"https://frontier.com/ftr-buy/?affiliateKey=17812d08-317e-4e5f-b5c6-da53d3ea9baf&utm_campaign=cross-sell&utm_medium=affiliate&utm_source=clearlink&utm_term=ftr-cart&utm_content=gofrontier&sc_camp={}&tune_id={REQUEST_ID}&phone_number=8556621821","buttonText":"Order Online","buttonIcon":"Cart"}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"go.frontier","short_name":"go.frontier","start_url":"/","background_color":"#FF0037","theme_color":"#FF0037","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a71c3250e67b30121836d9753a3f9b44"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
