import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const HeaderStyles = withTheme(() => (
  <Global
    styles={css`
      .leshen-header {
        // see notes line 17  
        z-index: 5 !important;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        padding-top: 12px;
        padding-bottom: 16px;
        background-color: #ff0037 !important;

      //    notes line 17 - it's flex tape fix specifically for primary-link & subnav text colors for frontier 
      //    added custom header styles, until we can patch this in leshen 
      //    then we should remove the custom header styles to avoid havoc  
          
      .primary-link {
          color: white !important;
          svg path {
              fill: #fff !important;
          }   
          .leshen-link {
              span {
                  color: #ffffff !important;
              }
          }
      }
          
        &.leshen-header-desktop {
          padding-bottom: 0;

          .subnav li:last-child,
          .subnav li:last-child a {
            border-bottom-right-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
          }

          .subnav {
            li {
                a {
                    color: #141928 !important;
                }
            }
            .subnav-container {
                ul {
                    div {
                        .mega-menu-subheading {
                            span {
                                color: #141928 !important;
                            }
                        }
                        li {
                            a {
                                color: #141928 !important;
                            }
                        }
                    }
                }
            }

          nav {
            flex-wrap: wrap;
          }

          .primary-link {
              line-height: 100%;
            &:first-of-type {
              margin-right: 0;
            }
          }

          .phone-link {
            align-self: flex-start;
            margin-bottom: 12px;
          }
        }

        .main-nav {
          background-color: #ff0037 !important;
        }

        /* Header Desktop CTA */
        .phone-link {
          font-weight: 700;
          font-size: 18px;
          line-height: 1;
          color: #ff0037 !important;
          transition: background-color 0.3s;
          background-color: #fff;
          padding: 12px 14px 8px;
          border-radius: 32px;

          &:hover {
            text-decoration: none;
          }

          &:active {
            color: #fff !important;
            background-color: #141928;
          }
        }

        img[alt='logo'] {
          display: block;
          width: 130px;
          height: 30px;
          margin-right: 30px;
        }

        .primary-link {
          color: #ffffff;
          align-self: flex-end;

          &:hover {
            /* .subnav {
              top: 70px !important;
            } */
          }
        }
      }

      ul li button {
        &:active {
          background-color: unset !important;
          color: unset !important;
        }
      }
      
      .leshen-header {
            //position: relative !important;
            //z-index: 5 !important;
          .leshen-header-desktop .subnav {
               //position: absolute !important;
               //z-index: 10 !important;
           }
       }
    
      .leshen-header-mobile {
        .menu-button svg path {
          fill: #fff !important;
        }
      }
    `}
  />
))

export default HeaderStyles
